import * as R from 'ramda';
import React, { Component } from 'react';
const CHILDREN_REGEX = /^children$/;
export default class Cell extends Component {
    constructor(props) {
        super(props);
    }
    get propsWithDefaults() {
        return this.props;
    }
    render() {
        const { attributes, classes, onClick, onDoubleClick, onMouseEnter, onMouseLeave, onMouseMove, style } = this.propsWithDefaults;
        return (React.createElement("td", Object.assign({ ref: 'td', children: this.props.children, tabIndex: -1, className: classes, onClick: onClick, onDoubleClick: onDoubleClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseMove: onMouseMove, onMouseOver: onMouseMove, style: style }, attributes)));
    }
    shouldComponentUpdate(nextProps) {
        const props = this.props;
        const childProps = this.getChildProps(props);
        const nextChildProps = this.getChildProps(nextProps);
        return R.any(key => !CHILDREN_REGEX.test(key) && props[key] !== nextProps[key], R.keysIn(props)) || R.any(key => childProps[key] !== nextChildProps[key], R.keysIn(childProps));
    }
    getChildProps(props) {
        return props && props.children && props.children[0] && props.children[0].props;
    }
}
