import Environment from 'core/environment';
import { memoizeOneFactory } from 'core/memoizer';
import { EdgesMatrices } from './type';
import { getDataCellEdges } from '.';
import { traverse2 } from 'core/math/matrixZipMap';
export default memoizeOneFactory((columns, styles, data, offset, active_cell, listViewStyle) => {
    if (data.length === 0 || columns.length === 0) {
        return;
    }
    const edges = new EdgesMatrices(data.length, columns.length, Environment.defaultEdge, true, !listViewStyle);
    traverse2(data, columns, (datum, column, i, j) => edges.setEdges(i, j, getDataCellEdges(datum, i + offset.rows, column)(styles)));
    if (active_cell) {
        edges.setEdges(active_cell.row - offset.rows, active_cell.column - offset.columns, {
            borderBottom: [Environment.activeEdge, Infinity],
            borderLeft: [Environment.activeEdge, Infinity],
            borderRight: [Environment.activeEdge, Infinity],
            borderTop: [Environment.activeEdge, Infinity]
        });
    }
    return edges;
});
