export function arrayMap(a1, fn) {
    const _a1_ = a1.length;
    const res = new Array(_a1_);
    for (let i = 0; i < _a1_; ++i) {
        res[i] = fn(a1[i], i);
    }
    return res;
}
export function arrayMap2(a1, a2, fn) {
    const _a1_ = a1.length;
    const res = new Array(_a1_);
    for (let i = 0; i < _a1_; ++i) {
        res[i] = fn(a1[i], a2[i], i);
    }
    return res;
}
export function arrayMap3(a1, a2, a3, fn) {
    const _a1_ = a1.length;
    const res = new Array(_a1_);
    for (let i = 0; i < _a1_; ++i) {
        res[i] = fn(a1[i], a2[i], a3[i], i);
    }
    return res;
}
export function arrayMapN(fn, ...arrays) {
    const a1 = arrays.slice(0, 1);
    const as = arrays.slice(1);
    const _a1_ = a1.length;
    const res = new Array(_a1_);
    for (let i = 0; i < _a1_; ++i) {
        res[i] = fn(i, a1[i], ...as.map(a => a[i]));
    }
    return res;
}
