import * as R from 'ramda';
import React from 'react';
import { Presentation } from 'dash-table/components/Table/props';
import CellInput from 'dash-table/components/CellInput';
import derivedCellEventHandlerProps, { Handler } from 'dash-table/derived/cell/eventHandlerProps';
import CellLabel from 'dash-table/components/CellLabel';
import CellDropdown from 'dash-table/components/CellDropdown';
import { memoizeOne } from 'core/memoizer';
import getFormatter from 'dash-table/type/formatter';
import { shallowClone } from 'core/math/matrixZipMap';
const mapData = R.addIndex(R.map);
const mapRow = R.addIndex(R.map);
var CellType;
(function (CellType) {
    CellType[CellType["Dropdown"] = 0] = "Dropdown";
    CellType[CellType["DropdownLabel"] = 1] = "DropdownLabel";
    CellType[CellType["Input"] = 2] = "Input";
    CellType[CellType["Label"] = 3] = "Label";
})(CellType || (CellType = {}));
function getCellType(active, editable, dropdown, presentation, is_loading) {
    switch (presentation) {
        case Presentation.Input:
            return (!active || !editable || is_loading) ? CellType.Label : CellType.Input;
        case Presentation.Dropdown:
            return (!dropdown || !editable) ? CellType.DropdownLabel : CellType.Dropdown;
        default:
            return (!active || !editable || is_loading) ? CellType.Label : CellType.Input;
    }
}
export default (propsFn) => new Contents(propsFn);
class Contents {
    constructor(propsFn, handlers = derivedCellEventHandlerProps(propsFn)) {
        this.handlers = handlers;
        this.partialGet = memoizeOne((columns, data, _offset, isFocused, dropdowns, data_loading) => {
            const formatters = R.map(getFormatter, columns);
            return mapData((datum, rowIndex) => mapRow((column, columnIndex) => this.getContent(false, isFocused, column, dropdowns && dropdowns[rowIndex][columnIndex], columnIndex, rowIndex, datum, formatters, data_loading), columns), data);
        });
        this.get = memoizeOne((contents, activeCell, columns, data, offset, isFocused, dropdowns, data_loading) => {
            if (!activeCell) {
                return contents;
            }
            const { row: iActive, column: jActive } = activeCell;
            const i = iActive - offset.rows;
            const j = jActive - offset.columns;
            if (i < 0 || j < 0 || data.length <= i || columns.length <= j) {
                return contents;
            }
            const formatters = R.map(getFormatter, columns);
            contents = shallowClone(contents);
            contents[i][j] = this.getContent(true, isFocused, columns[j], dropdowns && dropdowns[i][j], jActive, iActive, data[i], formatters, data_loading);
            return contents;
        });
    }
    getContent(active, isFocused, column, dropdown, columnIndex, rowIndex, datum, formatters, data_loading) {
        const className = [
            ...(active ? ['input-active'] : []),
            isFocused ? 'focused' : 'unfocused',
            'dash-cell-value'
        ].join(' ');
        const cellType = getCellType(active, column.editable, dropdown && dropdown.options, column.presentation, data_loading);
        switch (cellType) {
            case CellType.Dropdown:
                return (React.createElement(CellDropdown, { key: `column-${columnIndex}`, active: active, clearable: dropdown && dropdown.clearable, dropdown: dropdown && dropdown.options, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), value: datum[column.id], disabled: data_loading }));
            case CellType.Input:
                return (React.createElement(CellInput, { key: `column-${columnIndex}`, active: active, className: className, focused: isFocused, onChange: this.handlers(Handler.Change, rowIndex, columnIndex), onMouseUp: this.handlers(Handler.MouseUp, rowIndex, columnIndex), onPaste: this.handlers(Handler.Paste, rowIndex, columnIndex), type: column.type, value: datum[column.id] }));
            case CellType.DropdownLabel:
            case CellType.Label:
            default:
                const resolvedValue = cellType === CellType.DropdownLabel ?
                    this.resolveDropdownLabel(dropdown, datum[column.id]) :
                    formatters[columnIndex](datum[column.id]);
                return (React.createElement(CellLabel, { active: active, className: className, key: `column-${columnIndex}`, value: resolvedValue }));
        }
    }
    resolveDropdownLabel(dropdown, value) {
        const dropdownValue = dropdown && dropdown.options && dropdown.options.find(option => option.value === value);
        return dropdownValue ? dropdownValue.label : value;
    }
}
