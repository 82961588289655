import * as R from 'ramda';
import React from 'react';
import { memoizeOneFactory } from 'core/memoizer';
function renderFragment(cells, offset = 0) {
    return cells ?
        (React.createElement("table", { tabIndex: -1 },
            React.createElement("tbody", null, cells.map((row, idx) => React.createElement("tr", { key: `row-${idx + offset}` }, row))))) :
        null;
}
const isEmpty = (cells) => !cells ||
    cells.length === 0 ||
    cells[0].length === 0;
export default memoizeOneFactory((fixedColumns, fixedRows, cells, offset) => {
    // slice out fixed columns
    let fixedColumnCells = fixedColumns ?
        R.map(row => row.slice(0, R.reduceWhile(acc => acc.count < fixedColumns, (acc, cell) => {
            acc.cells++;
            acc.count += (cell.props.colSpan || 1);
            return acc;
        }, { cells: 0, count: 0 }, row).cells), cells) :
        null;
    cells = R.addIndex(R.map)((row, i) => row.slice((fixedColumnCells && fixedColumnCells[i].length) || 0), cells);
    // slice out fixed rows
    const fixedRowCells = fixedRows ?
        cells.slice(0, fixedRows) :
        null;
    cells = cells.slice(fixedRows);
    const fixedRowAndColumnCells = fixedRows && fixedColumnCells ?
        fixedColumnCells.slice(0, fixedRows) :
        null;
    fixedColumnCells = fixedColumnCells && fixedColumnCells.slice(fixedRows);
    return {
        grid: [
            [renderFragment(fixedRowAndColumnCells), renderFragment(fixedRowCells)],
            [renderFragment(fixedColumnCells), renderFragment(cells, offset)]
        ],
        empty: [
            [isEmpty(fixedRowAndColumnCells), isEmpty(fixedRowCells)],
            [isEmpty(fixedColumnCells), isEmpty(cells)]
        ]
    };
});
