import * as R from 'ramda';
import { memoizeOneFactory } from 'core/memoizer';
import sort from 'core/sorting';
import { TableAction } from 'dash-table/components/Table/props';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
const getter = (columns, data, filter_action, filter_query, sort_action, sort_by = []) => {
    const map = new Map();
    R.addIndex(R.forEach)((datum, index) => {
        map.set(datum, index);
    }, data);
    if (filter_action === TableAction.Native) {
        const tree = new QuerySyntaxTree(filter_query);
        data = tree.isValid ?
            tree.filter(data) :
            data;
    }
    const getNullyCases = (columnId) => {
        const column = R.find(c => c.id === columnId, columns);
        return (column && column.sort_as_null) || [];
    };
    const isNully = (value, columnId) => R.isNil(value) || R.includes(value, getNullyCases(columnId));
    if (sort_action === TableAction.Native) {
        data = sort(data, sort_by, isNully);
    }
    // virtual_indices
    const indices = R.map(datum => map.get(datum), data);
    return { data, indices };
};
export default memoizeOneFactory(getter);
