import React, { Component } from 'react';
import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import ControlledTable from 'dash-table/components/ControlledTable';
import 'react-select/dist/react-select.css';
import './Table.less';
import './style';
import './Dropdown.css';
import { isEqual } from 'core/comparer';
import derivedFilterMap from 'dash-table/derived/filter/map';
import controlledPropsHelper from './controlledPropsHelper';
import derivedPropsHelper from './derivedPropsHelper';
const DERIVED_REGEX = /^derived_/;
export default class Table extends Component {
    constructor(props) {
        super(props);
        this.__setProps = memoizeOne((setProps) => {
            return setProps ? (newProps) => {
                if (R.has('data', newProps)) {
                    const { data } = this.props;
                    newProps.data_timestamp = Date.now();
                    newProps.data_previous = data;
                }
                setProps(newProps);
            } : (newProps) => {
                this.setState(newProps);
            };
        });
        this.__setState = memoizeOne(() => (state) => this.setState(state));
        this.filterMap = derivedFilterMap();
        this.controlledPropsHelper = controlledPropsHelper();
        this.updateDerivedProps = derivedPropsHelper();
        this.state = {
            forcedResizeOnly: false,
            workFilter: {
                value: props.filter_query,
                map: this.filterMap(new Map(), props.filter_query, props.visibleColumns)
            },
            rawFilterQuery: '',
            scrollbarWidth: 0
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.filter_query === this.props.filter_query) {
            return;
        }
        this.setState(state => {
            const { workFilter: { map: currentMap, value } } = state;
            if (value !== nextProps.filter_query) {
                const map = this.filterMap(currentMap, nextProps.filter_query, nextProps.visibleColumns);
                return map !== currentMap ? { workFilter: { map, value } } : null;
            }
            else {
                return null;
            }
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        const props = this.props;
        const state = this.state;
        return R.any(key => !DERIVED_REGEX.test(key) && props[key] !== nextProps[key], R.keysIn(props)) || !isEqual(state, nextState);
    }
    render() {
        let controlled = this.controlledPropsHelper(this.controlledSetProps, this.controlledSetState, this.props, this.state);
        this.updateDerivedProps(controlled, this.controlledSetProps);
        return (React.createElement(ControlledTable, Object.assign({}, controlled)));
    }
    get controlledSetProps() {
        return this.__setProps(this.props.setProps);
    }
    get controlledSetState() {
        return this.__setState();
    }
}
