export var LexemeType;
(function (LexemeType) {
    LexemeType["BlockClose"] = "close-block";
    LexemeType["BlockOpen"] = "open-block";
    LexemeType["LogicalOperator"] = "logical-operator";
    LexemeType["RelationalOperator"] = "relational-operator";
    LexemeType["UnaryOperator"] = "unary-operator";
    LexemeType["Expression"] = "expression";
})(LexemeType || (LexemeType = {}));
export function boundLexeme(lexeme) {
    return { ...lexeme, if: () => false, terminal: false };
}
