import * as R from 'ramda';
import { ifColumnId, ifRowIndex, ifFilter } from 'dash-table/conditional';
import { TooltipSyntax } from 'dash-table/tooltips/props';
import { memoizeOne } from 'core/memoizer';
// 2^32-1 the largest value setTimout can take safely
export const MAX_32BITS = 2147483647;
function getSelectedTooltip(currentTooltip, tooltip_data, tooltip_conditional, tooltip_static, virtualized) {
    if (!currentTooltip) {
        return undefined;
    }
    const { id, row } = currentTooltip;
    if (id === undefined || row === undefined) {
        return undefined;
    }
    const appliedStaticTooltip = (tooltip_data &&
        tooltip_data.length > row &&
        tooltip_data[row] &&
        tooltip_data[row][id]) || tooltip_static[id];
    const conditionalTooltips = R.filter(tt => {
        return !tt.if ||
            (ifColumnId(tt.if, id) &&
                ifRowIndex(tt.if, row) &&
                ifFilter(tt.if, virtualized.data[row - virtualized.offset.rows]));
    }, tooltip_conditional);
    return conditionalTooltips.length ?
        conditionalTooltips.slice(-1)[0] :
        appliedStaticTooltip;
}
function convertDelay(delay) {
    return typeof delay === 'number' ?
        delay :
        0;
}
function convertDuration(duration) {
    return typeof duration === 'number' ?
        duration :
        MAX_32BITS;
}
function getDelay(delay, defaultTo) {
    return typeof delay === 'number' || delay === null ?
        convertDelay(delay) :
        defaultTo;
}
function getDuration(duration, defaultTo) {
    return typeof duration === 'number' || duration === null ?
        convertDuration(duration) :
        defaultTo;
}
export default memoizeOne((currentTooltip, tooltip_data, tooltip_conditional, tooltip_static, virtualized, defaultDelay, defaultDuration) => {
    const selectedTooltip = getSelectedTooltip(currentTooltip, tooltip_data, tooltip_conditional, tooltip_static, virtualized);
    let delay = convertDelay(defaultDelay);
    let duration = convertDuration(defaultDuration);
    let type = TooltipSyntax.Text;
    let value;
    if (selectedTooltip) {
        if (typeof selectedTooltip === 'string') {
            value = selectedTooltip;
        }
        else {
            delay = getDelay(selectedTooltip.delay, delay);
            duration = getDuration(selectedTooltip.duration, duration);
            type = selectedTooltip.type || TooltipSyntax.Text;
            value = selectedTooltip.value;
        }
    }
    return { delay, duration, type, value };
});
