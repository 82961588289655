import * as R from 'ramda';
export default function lexer(lexicon, query) {
    let result = [];
    while (query.length) {
        query = query.replace(/^\s+/, '');
        const previous = result.slice(-1)[0];
        const previousLexeme = previous ? previous.lexeme : null;
        let lexemes = lexicon.filter(lexeme => lexeme.if &&
            (!Array.isArray(lexeme.if) ?
                lexeme.if(result, previous) :
                (previousLexeme ?
                    lexeme.if && lexeme.if.indexOf(previousLexeme.type) !== -1 :
                    lexeme.if && lexeme.if.indexOf(undefined) !== -1)));
        const next = R.find(lexeme => lexeme.regexp.test(query), lexemes);
        if (!next) {
            return { lexemes: result, valid: false, error: query };
        }
        const value = (query.match(next.regexp) || [])[next.regexpMatch || 0];
        result.push({ lexeme: next, value });
        query = query.substring(value.length);
    }
    const [terminalPrevious, last] = [
        undefined,
        undefined,
        ...result
    ].slice(-2);
    const terminal = !last ||
        (typeof last.lexeme.terminal === 'function' ?
            last.lexeme.terminal(result, terminalPrevious) :
            last.lexeme.terminal);
    return {
        lexemes: result,
        valid: terminal
    };
}
