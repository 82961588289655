import * as R from 'ramda';
export var SortDirection;
(function (SortDirection) {
    SortDirection["Ascending"] = "asc";
    SortDirection["Descending"] = "desc";
    SortDirection["None"] = "none";
})(SortDirection || (SortDirection = {}));
export const defaultIsNully = (value, _) => R.isNil(value);
export default (data, sortBy, isNully = defaultIsNully) => {
    if (!sortBy.length) {
        return data;
    }
    return R.sortWith(R.map(sort => {
        return sort.direction === SortDirection.Descending ?
            R.comparator((d1, d2) => {
                const id = sort.column_id;
                const prop1 = d1[id];
                const prop2 = d2[id];
                if (isNully(prop1, sort.column_id)) {
                    return false;
                }
                else if (isNully(prop2, sort.column_id)) {
                    return true;
                }
                return prop1 > prop2;
            }) :
            R.comparator((d1, d2) => {
                const id = sort.column_id;
                const prop1 = d1[id];
                const prop2 = d2[id];
                if (isNully(prop1, sort.column_id)) {
                    return false;
                }
                else if (isNully(prop2, sort.column_id)) {
                    return true;
                }
                return prop1 < prop2;
            });
    }, sortBy), data);
};
