import { memoizeOneFactory } from 'core/memoizer';
import { clearSelection } from 'dash-table/utils/actions';
import { TableAction } from 'dash-table/components/Table/props';
export function lastPage(data, page_size) {
    return Math.ceil(data.length / page_size);
}
function makePaginator(params) {
    if (params === null) {
        return {
            loadNext() { },
            loadPrevious() { },
            loadFirst() { },
            loadLast() { },
            loadPage() { },
            hasPrevious() { return true; },
            hasNext() { return true; },
            isLast() { return false; },
            lastPage: undefined
        };
    }
    let { setProps, page_current, page_count } = params;
    function updatePage() {
        setProps({
            page_current,
            ...clearSelection
        });
    }
    function loadPage(page) {
        page = Math.max(0, page);
        page = page_count ? Math.min(page_count - 1, page) : page;
        page_current = page;
        updatePage();
    }
    return {
        loadNext: () => loadPage(page_current + 1),
        loadPrevious: () => loadPage(page_current - 1),
        loadFirst: () => loadPage(0),
        loadPage,
        loadLast: () => {
            if (page_count) {
                loadPage(page_count - 1);
            }
        },
        hasPrevious: () => {
            return page_current !== 0;
        },
        hasNext: () => {
            return page_count ? page_current !== page_count - 1 : true;
        },
        isLast: () => {
            return page_count ? page_current === page_count - 1 : false;
        },
        lastPage: page_count ? Math.max(0, page_count - 1) : undefined
    };
}
const getter = (table_action, page_current, page_size, page_count, setProps, data) => {
    if (table_action === TableAction.Native) {
        page_count = lastPage(data, page_size);
    }
    if (page_count) {
        page_count = Math.max(page_count, 1);
    }
    return makePaginator(table_action === TableAction.None ? null : {
        setProps,
        page_current,
        page_count
    });
};
export default memoizeOneFactory(getter);
