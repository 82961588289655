import Logger from 'core/Logger';
import { LexemeType } from 'core/syntax-tree/lexicon';
export const blockClose = {
    nesting: -1,
    regexp: /^\)/,
    type: LexemeType.BlockClose
};
export const blockOpen = {
    evaluate: (target, tree) => {
        Logger.trace('evaluate -> ()', target, tree);
        const t = tree;
        return t.block.lexeme.evaluate(target, t.block);
    },
    type: LexemeType.BlockOpen,
    nesting: 1,
    subType: '()',
    priority: 1,
    regexp: /^\(/,
    syntaxer: (lexs) => {
        return Object.assign({
            block: lexs.slice(1, lexs.length - 1)
        }, lexs[0]);
    }
};
