import { map, range, xprod } from 'ramda';
export function makeCell(row, column, columns, viewport) {
    const cell = {
        row,
        column,
        column_id: columns[column].id
    };
    const rowId = viewport.data[row].id;
    if (rowId !== undefined) {
        cell.row_id = rowId;
    }
    return cell;
}
export function makeSelection(bounds, columns, viewport) {
    const { minRow, maxRow, minCol, maxCol } = bounds;
    return map(rc => makeCell(rc[0], rc[1], columns, viewport), xprod(range(minRow, maxRow + 1), range(minCol, maxCol + 1)));
}
