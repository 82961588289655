import React, { PureComponent } from 'react';
import IsolatedInput from 'core/components/IsolatedInput';
import TableClipboardHelper from 'dash-table/utils/TableClipboardHelper';
export default class ColumnFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.submit = (value) => {
            const { setFilter } = this.props;
            setFilter({
                target: { value }
            });
        };
        this.state = {
            value: props.value
        };
    }
    render() {
        const { classes, columnId, isValid, style, value } = this.props;
        return (React.createElement("th", { className: classes + (isValid ? '' : ' invalid'), "data-dash-column": columnId, style: style },
            React.createElement(IsolatedInput, { onCopy: (e) => {
                    e.stopPropagation();
                    TableClipboardHelper.clearClipboard();
                }, onPaste: (e) => {
                    e.stopPropagation();
                }, value: value, placeholder: `filter data...`, stopPropagation: true, submit: this.submit })));
    }
}
