import * as R from 'ramda';
import Logger from 'core/Logger';
import { LexemeType } from 'core/syntax-tree/lexicon';
const checkPrimality = (c) => {
    if (c === 2) {
        return true;
    }
    if (c < 2 || c % 2 === 0) {
        return false;
    }
    for (let n = 3; n * n <= c; n += 2) {
        if (c % n === 0) {
            return false;
        }
    }
    return true;
};
function evaluator(target, tree) {
    Logger.trace('evaluate -> unary', target, tree);
    Logger.trace('evaluate -> unary', target, tree);
    const t = tree;
    const opValue = t.left.lexeme.resolve(target, t.left);
    return opValue;
}
function relationalSyntaxer([left, lexeme]) {
    return Object.assign({ left }, lexeme);
}
function relationalEvaluator(fn) {
    return (target, tree) => fn(evaluator(target, tree));
}
var UnaryOperator;
(function (UnaryOperator) {
    UnaryOperator["Not"] = "!";
})(UnaryOperator || (UnaryOperator = {}));
const LEXEME_BASE = {
    present: (tree) => tree.value,
    priority: 0,
    syntaxer: relationalSyntaxer,
    type: LexemeType.UnaryOperator
};
export const not = {
    evaluate: (target, tree) => {
        Logger.trace('evaluate -> unary not', target, tree);
        const t = tree;
        return !t.right.lexeme.evaluate(target, t.right);
    },
    type: LexemeType.UnaryOperator,
    subType: UnaryOperator.Not,
    priority: 1.5,
    regexp: /^!/,
    syntaxer: (lexs) => {
        return Object.assign({
            right: lexs.slice(1, lexs.length)
        }, lexs[0]);
    }
};
export const isBool = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'boolean'),
    regexp: /^(is bool)/i
}, LEXEME_BASE);
export const isEven = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'number' && opValue % 2 === 0),
    regexp: /^(is even)/i
}, LEXEME_BASE);
export const isBlank = R.merge({
    evaluate: relationalEvaluator(opValue => opValue === undefined || opValue === null || opValue === ''),
    regexp: /^(is blank)/i
}, LEXEME_BASE);
export const isNil = R.merge({
    evaluate: relationalEvaluator(opValue => opValue === undefined || opValue === null),
    regexp: /^(is nil)/i
}, LEXEME_BASE);
export const isNum = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'number'),
    regexp: /^(is num)/i
}, LEXEME_BASE);
export const isObject = R.merge({
    evaluate: relationalEvaluator(opValue => opValue !== null && typeof opValue === 'object'),
    regexp: /^(is object)/i
}, LEXEME_BASE);
export const isOdd = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'number' && opValue % 2 === 1),
    regexp: /^(is odd)/i
}, LEXEME_BASE);
export const isPrime = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'number' && checkPrimality(opValue)),
    regexp: /^(is prime)/i
}, LEXEME_BASE);
export const isStr = R.merge({
    evaluate: relationalEvaluator(opValue => typeof opValue === 'string'),
    regexp: /^(is str)/i
}, LEXEME_BASE);
