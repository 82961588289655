import * as R from 'ramda';
import { ColumnType } from 'dash-table/components/Table/props';
import { QuerySyntaxTree } from 'dash-table/syntax-tree';
function ifAstFilter(ast, datum) {
    return ast.isValid && ast.evaluate(datum);
}
export function ifColumnId(condition, columnId) {
    return !condition ||
        condition.column_id === undefined ||
        condition.column_id === columnId;
}
export function ifColumnType(condition, columnType) {
    return !condition ||
        condition.column_type === undefined ||
        condition.column_type === (columnType || ColumnType.Any);
}
export function ifRowIndex(condition, rowIndex) {
    if (!condition ||
        condition.row_index === undefined) {
        return true;
    }
    const rowCondition = condition.row_index;
    return typeof rowCondition === 'number' ?
        rowIndex === rowCondition :
        rowCondition === 'odd' ? rowIndex % 2 === 1 : rowIndex % 2 === 0;
}
export function ifHeaderIndex(condition, headerIndex) {
    if (!condition ||
        condition.header_index === undefined) {
        return true;
    }
    const headerCondition = condition.header_index;
    return typeof headerCondition === 'number' ?
        headerIndex === headerCondition :
        headerCondition === 'odd' ? headerIndex % 2 === 1 : headerIndex % 2 === 0;
}
export function ifFilter(condition, datum) {
    return !condition ||
        condition.filter_query === undefined ||
        ifAstFilter(new QuerySyntaxTree(condition.filter_query), datum);
}
export function ifEditable(condition, isEditable) {
    if (!condition ||
        condition.column_editable === undefined) {
        return true;
    }
    return isEditable === condition.column_editable;
}
export const matchesDataCell = (datum, i, column) => R.filter((style => style.matchesRow(i) &&
    style.matchesColumn(column) &&
    style.matchesFilter(datum)));
export const matchesFilterCell = (column) => R.filter((style => style.matchesColumn(column)));
export const matchesHeaderCell = (i, column) => R.filter((style => style.matchesRow(i) &&
    style.matchesColumn(column)));
export const matchesDataOpCell = (datum, i) => R.filter((style => !style.checksColumn() &&
    style.matchesRow(i) &&
    style.matchesFilter(datum)));
export const getFilterOpStyles = R.filter((style => !style.checksColumn()));
export const getHeaderOpStyles = (i) => R.filter((style => style.matchesRow(i) &&
    !style.checksColumn()));
