import * as R from 'ramda';
import Environment from 'core/environment';
import { memoizeOneFactory } from 'core/memoizer';
import { EdgesMatrices } from './type';
import { getFilterCellEdges } from '.';
import { traverse2 } from 'core/math/matrixZipMap';
export default memoizeOneFactory((columns, showFilters, map, styles, listViewStyle) => {
    if (!showFilters || columns.length === 0) {
        return;
    }
    const edges = new EdgesMatrices(1, columns.length, Environment.defaultEdge, true, !listViewStyle);
    traverse2(R.range(0, 1), columns, (_, column, i, j) => {
        edges.setEdges(i, j, getFilterCellEdges(column)(styles));
        const ast = map.get(column.id.toString());
        if (ast && !ast.isValid) {
            edges.setEdges(i, j, {
                borderBottom: [Environment.activeEdge, Infinity],
                borderLeft: [Environment.activeEdge, Infinity],
                borderRight: [Environment.activeEdge, Infinity],
                borderTop: [Environment.activeEdge, Infinity]
            });
        }
    });
    return edges;
});
