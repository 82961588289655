import * as R from 'ramda';
import { memoizeOne } from 'core/memoizer';
import CellFactory from 'dash-table/components/CellFactory';
import EdgeFactory from 'dash-table/components/EdgeFactory';
import FilterFactory from 'dash-table/components/FilterFactory';
import HeaderFactory from 'dash-table/components/HeaderFactory';
import { clearSelection } from 'dash-table/utils/actions';
const handleSetFilter = (setProps, setState, filter_query, rawFilterQuery, map) => {
    setProps({ filter_query, ...clearSelection });
    setState({ workFilter: { map, value: filter_query }, rawFilterQuery });
};
function propsAndMapFn(propsFn, setFilter) {
    const props = propsFn();
    return R.merge(props, { map: props.workFilter.map, setFilter });
}
export default (propsFn) => {
    const setFilter = memoizeOne((setProps, setState) => handleSetFilter.bind(undefined, setProps, setState));
    const cellFactory = new CellFactory(propsFn);
    const augmentedPropsFn = () => {
        const props = propsFn();
        return propsAndMapFn(propsFn, setFilter(props.setProps, props.setState));
    };
    const filterFactory = new FilterFactory(augmentedPropsFn);
    const headerFactory = new HeaderFactory(augmentedPropsFn);
    const edgeFactory = new EdgeFactory(propsFn);
    const merge = memoizeOne((data, filters, headers) => {
        const cells = [];
        cells.push(...headers);
        cells.push(...filters);
        cells.push(...data);
        return cells;
    });
    return () => {
        const edges = edgeFactory.createEdges();
        const dataCells = cellFactory.createCells(edges.dataEdges, edges.dataOpEdges);
        const filters = filterFactory.createFilters(edges.filterEdges, edges.filterOpEdges);
        const headers = headerFactory.createHeaders(edges.headerEdges, edges.headerOpEdges);
        return merge(dataCells, filters, headers);
    };
};
